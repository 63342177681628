import _ from 'underscore';
import { useEffect, useState } from 'react';

const threshold = 768;

const getBreakpoint = (width: number): 'sp' | 'pc' => {
  if (width < threshold) {
    return 'sp';
  }
  return 'pc';
};

const useBreakpoint = () => {
  const [innerWidth, setInnerWidth] = useState<number>();
  const [breakpoint, setBreakpoint] = useState<'sp' | 'pc'>();

  useEffect(() => {
    // resize の度に処理しないようthrottle で間引く
    const calcInnerWidth = () => {
      setBreakpoint(getBreakpoint(window.innerWidth));
      setInnerWidth(window.innerWidth);
    };
    const calc = _.throttle(calcInnerWidth, 200);

    window.addEventListener('resize', calc);
    window.addEventListener('orientationchange', calc, false);

    calcInnerWidth();

    return () => {
      window.removeEventListener('resize', calc);
      window.removeEventListener('orientationchange', calc);
    };
  }, []);

  return { innerWidth, breakpoint };
};

export default useBreakpoint;
