import React, { useEffect, useRef, useState } from 'react';
import Menu from './Menu';
import HeaderContents from './HeaderContents';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { State } from '../../redux/state';
import '../../styles/common/header.scss';

const Header = (props: any) => {
  const ref = useRef<HTMLDivElement>(null);
  const [isLightTheme, setIsLightTheme] = useState<boolean>(false);
  const [menuActive, setMenuActive] = useState(false);

  useEffect(() => {
    if (!ref.current) return;
    const menu: any = ref.current;
    if (props.lightThemeBottom < 0 + menu.getBoundingClientRect().height) {
      setIsLightTheme(false);
      return;
    }
    if (props.lightThemeTop < 0 + menu.getBoundingClientRect().top) {
      setIsLightTheme(true);
      return;
    } else {
      setIsLightTheme(false);
      return;
    }
  }, [props.lightThemeTop, props.lightThemeBottom]);

  const hideMenu: boolean = useSelector((state: State) => state.hideMenu);

  return (
    <section className="header">
      <Menu menuActive={menuActive} setMenuActive={setMenuActive} />
      <div className="header-left">
        <Link to="/">
          <div className="header-left_logo">
            <img src={`${IMAGE_PATH}images/logo_main.png`} alt="" />
          </div>
        </Link>
      </div>
      <div className="header-right">
        {!hideMenu && (
          <HeaderContents
            setMenuActive={setMenuActive}
            date={props.date}
            lightTheme={isLightTheme}
            ref={ref}
          />
        )}
      </div>
    </section>
  );
};

export default Header;
