import '../../styles/common/wrapper.scss';
import { useRef, useEffect, useState } from 'react';
import Canvas from './Canvas';
import Color from 'color';

type Props = {
  bgColor?: string;
  dotColor?: string;
  children?: any;
};

export default function App(props: Props) {
  const contentRef = useRef<HTMLDivElement | null>(null);
  const [contentHeight, setContentHeight] = useState<number>(0);
  const bgColor: Color = props.bgColor ? Color(props.bgColor) : Color.rgb(16, 35, 52);

  const contentLoaded = (e?: any) => {
    let rect = contentRef.current?.getBoundingClientRect();
    // console.log(rect, (contentRef.current as HTMLDivElement).offsetHeight);
    if (rect?.height && rect?.height !== contentHeight) {
      setContentHeight(rect?.height);
    }
  };

  useEffect(() => {
    const intervalTimer = setInterval(() => {
      contentLoaded();
    }, 100);
    window.addEventListener('DOMContentLoaded', contentLoaded);
    window.addEventListener('resize', contentLoaded);
    return () => {
      clearInterval(intervalTimer);
      window.removeEventListener('DOMContentLoaded', contentLoaded);
      window.removeEventListener('resize', contentLoaded);
    };
  }, []);

  return (
    <div className="wrapper" style={{ backgroundColor: bgColor.string() }}>
      <div className="content" ref={contentRef}>
        {props.children}
      </div>
      <Canvas height={contentHeight} bgColor={props.bgColor} dotColor={props.dotColor} />
    </div>
  );
}
