import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';

const statuses = ['publish', 'draft', 'private'];
const config = (): AxiosRequestConfig => {
  let headers: any = {
    ['content-type']: 'application/json',
  };
  if (window.NONCE && window.NONCE.indexOf('<?php') < 0) {
    headers['X-WP-Nonce'] = window.NONCE;
  }
  return {
    headers: headers,
  };
};

export const fetch = (endpoint: string) => {
  const forSinglePage = endpoint.indexOf('/works/') >= 0;

  //ゲストユーザーまたはシングルページの読み込み
  if (!window.NONCE || forSinglePage || window.NONCE.indexOf('<?php') >= 0) {
    return axios.get(process.env.REACT_APP_API_URL + endpoint, config());
  }

  //ログイン済みユーザーがWorks/People/Servicesにアクセス
  return (async () => {
    const all: Promise<any>[] = statuses.map((status, index) =>
      axios.get(process.env.REACT_APP_API_URL + endpoint, {
        ...config(),
        params: {
          status: status,
        },
      })
    );
    const responses = await Promise.all(all);

    return {
      data: responses.flatMap((res, index) => res.data),
    };
  })();
};

export const fetchNote = () => axios('/date.php');
