import '../../styles/common/wrapper.scss';
import { useRef, useEffect, useState } from 'react';
import * as PIXI from 'pixi.js';
import Color from 'color';
import { DotFilter } from '../../filters/dot/DotFilter';
import Ripple from '../../filters/dot/ripple';

type Props = {
  bgColor?: string;
  dotColor?: string;
  height: number;
};

export default function App(props: Props) {
  const isPc = document.body.clientWidth >= 768;
  const amount = isPc ? 15 : 5;
  const boxSize = isPc ? 20 : 10;

  const bgRef = useRef<HTMLDivElement>(null);
  const [loaded, setLoaded] = useState<boolean>(false);
  const container1 = new PIXI.Container();
  const container2 = new PIXI.Container();
  const blurFilter = new PIXI.filters.BlurFilter();
  blurFilter.blur = 50;
  const bgColor: Color = props.bgColor ? Color(props.bgColor) : Color.rgb(16, 35, 52);
  const dotColor: Color = props.dotColor ? Color(props.dotColor) : Color('#152F45');

  const init = () => {
    if (bgRef.current === null) return;
    const app = new PIXI.Application({
      backgroundColor: Number(bgColor.rgbNumber()),
      resizeTo: bgRef.current as HTMLElement,
    });
    const bg = new PIXI.Graphics();

    const myFilter = new DotFilter({
      width: app.screen.width,
      height: app.screen.height,
      r: dotColor.red() / 255,
      g: dotColor.green() / 255,
      b: dotColor.blue() / 255,
      box: boxSize,
    });

    container1.filters = [blurFilter, myFilter];

    app.stage.interactive = true;
    app.stage.addChild(container1);
    app.stage.addChild(container2);

    container1.addChild(bg);
    const ripples = new Array(amount)
      .fill(null)
      .map(() => new Ripple(app.screen.width, app.screen.height));
    ripples.forEach((r) => {
      container1.addChild(r.ripple);
      container2.addChild(r.bone);
    });

    app.ticker.maxFPS = 10;
    app.ticker.add((delta) => {
      bg.clear();
      bg.beginFill(0xffffff);
      bg.drawRect(0, 0, app.screen.width, app.screen.height);
      bg.endFill();

      ripples.forEach((r) => {
        r.update(app.screen.width, app.screen.height, false);
      });
    });

    bgRef.current?.appendChild(app.view);
  };

  //resize
  useEffect(() => {
    if (props.height === 0) return;
    const myFilter = new DotFilter({
      width: document.body.clientWidth,
      height: props.height,
      r: dotColor.red() / 255,
      g: dotColor.green() / 255,
      b: dotColor.blue() / 255,
      box: boxSize,
    });

    container1.filters = [blurFilter, myFilter];
  }, [props.height]);

  //initialize
  useEffect(() => {
    if (!bgRef) return;
    if (props.height === 0) return;

    if (!loaded) {
      init();
      setLoaded(true);
    }
  }, [bgRef, props.height]);

  return <div className="bg" ref={bgRef} style={{ height: props.height }} />;
}
