import React, { useEffect, useState, useRef } from 'react';
import { Link, animateScroll as scroll } from 'react-scroll';
import { delay } from '../../utils/delay';
import '../../styles/top/new_arrival_news.scss';
import { Information } from '../../types/information';
import cn from 'classnames';

type Props = {
  informations: [Information];
  lightThemeTop: number;
  lightThemeBottom: number;
  footerTopHeight: number;
};

function NewArrivalNews(props: Props) {
  const ref = useRef<HTMLDivElement>(null);
  const [news, setNews] = useState<string>();
  const [newsChange, setNewsChange] = useState<boolean>();
  const [newsHidden, setNewsHidden] = useState<boolean>(false);
  const [colorChange, setColorChange] = useState<boolean>(false);
  const [contactBtnHidden, setContactBtnHidden] = useState<boolean>(false);

  useEffect(() => {
    props.informations.sort(function (a, b) {
      if (a.date > b.date) {
        return -1;
      } else {
        return 1;
      }
    });
    const contents: Array<string> = [];
    for (let i = 0; i < 3; i++) {
      contents.push(textCount(props.informations[i].title.rendered));
    }
    setNews(contents[0]);
    function textCount(str: string) {
      if (str.length > 22) {
        return str.substr(0, 22) + '…';
      }
      return str;
    }

    let n = 1;
    const newsUpdate = async () => {
      await delay(200);
      setNewsChange(true);
      await delay(1200);
      setNews(contents[n]);
      if (n === 2) n = 0;
      else n++;
      setNewsChange(false);
    };
    setInterval(newsUpdate, 8000);
  }, []);

  useEffect(() => {
    if (!ref.current) return;
    const target: any = ref.current;
    if (props.footerTopHeight !== 0 && props.footerTopHeight - window.innerHeight < 0) {
      setContactBtnHidden(true);
    } else {
      setContactBtnHidden(false);
    }

    if (props.lightThemeBottom - window.innerHeight < 0 - target.getBoundingClientRect().height) {
      setColorChange(false);
      return;
    }

    if (props.lightThemeTop - window.innerHeight < 0 - target.getBoundingClientRect().height) {
      // setNewsHidden(true);
      setColorChange(true);
      return;
    } else {
      // setNewsHidden(false);
      setColorChange(false);
      return;
    }
  }, [props.lightThemeTop, props.lightThemeBottom, props.footerTopHeight]);

  return (
    <div>
      <Link to="news" spy={true} smooth={true}>
        <div
          className={cn('new_arrival_news', 'pc_only', {
            new_arrival_news_black: colorChange,
            new_arrival_news_hidden: newsHidden,
          })}
        >
          <p className="title">News</p>
          <div className={['slide-news', 'r', newsChange ? null : 'slide-news_change'].join(' ')}>
            <span className={['text', newsChange ? 'text_change' : null].join(' ')}>{news}</span>
          </div>
        </div>
      </Link>

      <a className="pc_only" href="mailto:platinum_pr@vectorinc.co.jp" target="_blank">
        <div
          className={cn('contact-btn', {
            'contact-btn_bgblue': colorChange,
            'contact-btn_hidden': contactBtnHidden,
          })}
          ref={ref}
        >
          Contact
          <span className={cn('contact-circle', { ['contact-circle_white']: colorChange })}></span>
        </div>
      </a>
    </div>
  );
}

export default NewArrivalNews;
