import React from 'react';
import logo from '../../public/images/footer-logo.png';
import '../../styles/common/footer.scss';

function Footer() {
  return (
    <section className="footer">
      <div className="over-review">
        <div className="d-flex space-between r">
          <div className="info">
            <ul>
              <li>
                〒１０７-００５２ 東京都港区赤坂４丁目１５−1
                <br className="sp_only" /> 赤坂ガーデンシティ１４F
              </li>
              <li>
                Akasaka Garden City. 14F. 4-15-1.
                <br className="sp_only" />
                Akasaka. Minatoku. Tokyo. Japan 107-0052
              </li>
              <br className="sp_only" />
              <li>設立: 2004.5.6 / 従業員数: 220名</li>
              <li>Tel: 03-5572-6070</li>
            </ul>
            <p className="privacy">
              <a href="https://vectorinc.co.jp/privacy" rel="noreferrer" target="_blank">Privacy policy</a>
            </p>
            <div className="links">
              <p>
                <a href="mailto:platinum_pr@vectorinc.co.jp" rel="noreferrer" target="_blank">
                  Contact<span>/</span>
                </a>
              </p>
              <p>
                <a href="https://prnow.platinuminc.jp/" rel="noreferrer" target="_blank">
                  note<span>/</span>
                </a>
              </p>
              <p>
                <a href="https://twitter.com/Platinum_vector" rel="noreferrer" target="_blank">
                  Twitter<span>/</span>
                </a>
              </p>
              <p>
                <a href="https://www.facebook.com/platinuminc.pr" target="_blank">
                  Facebook<span>/</span>
                </a>
              </p>
              <p>
                <a
                  href="https://note.com/platinum_career/"
                  rel="noreferrer"
                  target="_blank"
                >
                  Careers
                </a>
              </p>
            </div>
          </div>
          <div className="footer-logo">
            <div>
              <img src={`${IMAGE_PATH}images/footer-logo.png`} alt="" />
              <span>©Platinum inc.</span>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Footer;
