import React from 'react';
import Slider from 'react-slick';
import { Information } from '../../types/information';
import dayjs from 'dayjs';
import '../../styles/top/news.scss';

type Props = {
  informations: [Information];
};

function News(props: Props) {
  const settings = {
    dots: false,
    arrows: true,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    centerMode: false,
    variableWidth: true,
    responsive: [
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          arrows: false,
        },
      },
    ],
  };

  return (
    <section className="news" id="news">
      <p className="section-padding base-title f-sky_blue f-black">News</p>
      {props.informations ? (
        <Slider className="slider" {...settings}>
          {props.informations.map((item: Information, key: number) => {
            return (
              <a href={item.acf.link} target="_blank" key={key}>
                <div className="slide-item">
                  <div className="img-inner img-hover">
                    {/* 画像の有無で分岐 */}
                    {item.acf.main_visual.url ? (
                      <img src={item.acf.main_visual.url} alt={item.acf.main_visual.alt} />
                    ) : (
                      <img src={`${IMAGE_PATH}images/news_noimage.svg`} alt="" />
                    )}
                  </div>
                  <div className="news-date">{dayjs(item.acf.date).format("YYYY.MM.DD")}</div>
                  <div className="news-title db">{item.title.rendered}</div>
                </div>
              </a>
            );
          })}
        </Slider>
      ) : null}
    </section>
  );
}

export default News;
