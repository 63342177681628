import React, { useEffect, useState } from 'react';
import Slider from 'react-slick';
import VisibilitySensor from 'react-visibility-sensor';

import Wrapper from '../components/common/Wrapper';
import Header from '../components/common/Header';
import Footer from '../components/common/Footer';
import Loading from '../components/common/Loading';
import ScrollToTop from '../components/common/ScrollToTop';
import TextDelay from '../components/common/TextDelay';
import ScrollMenuClass from '../components/common/ScrollMenuClass';
import { delay } from '../utils/delay';

import '../styles/common/reset.css';
import '../styles/common/layout.scss';

import { doFetch } from '../hooks/useApi';
import { Work } from '../types/work';
import consistant from '../utils/consistant';

function WorkComponent(props: any) {
  const [lightThemeTop, setLightThemeTop] = useState<number>(0);
  const [lightThemeBottom, setLightThemeBottom] = useState<number>(0);
  const [work, setWork] = useState<Work>();
  const [otherWorks, setOtherWorks] = useState<Work[]>();
  //loading
  const [progress, setProgress] = useState<number>(0);
  const [loadingVisible, setLoadingVisible] = useState<boolean>(true);
  const [close, setClose] = useState<boolean>(false);
  const [scrollFlag, setScrollFlag] = useState<boolean>(false);
  const [spTextDelayFlag, setSpTextDelayFlag] = useState<boolean>(false);

    
  function onChange (isVisible:boolean) {
    if(!isVisible) return
    setScrollFlag(isVisible);
  }
  const ovservePosition = (top: number, bottom: number) => {
    setLightThemeBottom(bottom);
    setLightThemeTop(top);
  };


  const settings = {
    dots: false,
    arrows: true,
    infinite: false,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    centerMode: false,
    variableWidth: true,
    responsive: [
      {
        breakpoint: 767,
        settings: {
          arrows: false,
          slidesToShow: 1,
          variableWidth: true,
        },
      },
    ],
  };

  useEffect(() => {
    const id: number = props.match.params.id;
    window.gtag('config', window.GA_MEASUREMENT_ID, {'page_path': `/works/${id}`});
    (async () => {
      const work: Work = await doFetch(`/works/${id}`);
      setWork(work);
      setProgress(50);
      const otherWorks: [Work] = await doFetch('/works');
      const otherData: Work[] = otherWorks.filter((item) => item.id != id);
      setOtherWorks(otherData);
      setProgress(100);
      //closing loading
      await delay(800);
      setClose(true);
      await delay(consistant.pageTransitionDuration);
      setLoadingVisible(false);
      setSpTextDelayFlag(true)
    })();
  }, []);

  return (
    <div className="Work">
      {loadingVisible && <Loading progress={progress} startClose={close} />}
      <ScrollToTop />
      {work ? (
        <div className="work-wrap">
          <section className="mv">
            <div
              className="mv-inner"
              style={{ backgroundImage: `url(${work.acf.main_visual.url})` }}
            >
              <Header lightThemeTop={lightThemeTop} lightThemeBottom={lightThemeBottom} />
            </div>
          </section>
          <Wrapper>
            <section className="main-text">
              <div className="title-box">
                <VisibilitySensor onChange={onChange}>
                  <h1 className="main-title">
                    <span className="pc_only">
                      <TextDelay className={scrollFlag ? "text-delay" : "text-delay_hidden"}>
                        {work.acf.title}
                      </TextDelay>
                    </span>
                    <span className="sp_only">
                      <TextDelay className={spTextDelayFlag ? "text-delay" : "text-delay_hidden"}>
                        {work.acf.title}
                      </TextDelay> 
                    </span>    
                  </h1>
                </VisibilitySensor>
                <p className="client_sp">Client: {work.acf.client}</p>
                <p className="main-title_sub">
                  {work.acf.tags
                    ? work.acf.tags.map((item, key) => {
                        return (
                          <span key={key}>
                            {item.tag} <br />
                          </span>
                        );
                      })
                    : null}
                </p>
              </div>
              <p className="client_pc">Client: {work.acf.client}</p>
              <p className="sub-title db">
                <p className="section-bar sp_only"></p>
                <span dangerouslySetInnerHTML={{ __html: work.acf.sub_title }}></span>
              </p>
              <div className="work-message r">
                <p dangerouslySetInnerHTML={{ __html: work.acf.content }}></p>
              </div>
            </section>

            {work.acf.photos.length > 0 ? (
              <section className="work-images">
                <ul>
                  {work.acf.photos.map((item, key) => {
                    return item.photo ? (
                      <li key={key}>
                        <img src={item.photo.url} alt={item.photo.alt} />
                      </li>
                    ) : null;
                  })}
                </ul>
              </section>
            ) : null}

            {/*  */}
          </Wrapper>
          <ScrollMenuClass updatePosition={ovservePosition}>
            <section className="other-works">
              <div className="base-title section-padding f-black">Other Works</div>
              <Slider className="slider" {...settings}>
                {otherWorks
                  ? otherWorks.map((item, index) => {
                      return (
                        <a href={'/works/' + item.id} key={index}>
                          <div className="slide-item img-hover">
                            <img src={item.acf.thumbnail.url} alt={item.acf.thumbnail.alt} />
                            <p className="work-name">{item.acf.title}</p>
                          </div>
                        </a>
                      );
                    })
                  : null}
              </Slider>
            </section>
          </ScrollMenuClass>
          <Footer />
        </div>
      ) : null}
    </div>
  );
}

export default WorkComponent;
