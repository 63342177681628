import React, { useEffect, useState } from 'react';
import cn from 'classnames';
import { Person } from '../../types/person';
import { useScrollDisabled } from '../../hooks/useScrollDisabled';
import { useDispatch } from 'react-redux';
import '../../styles/common/people_modal.scss';
import { hideMenu } from '../../redux';
import consistant from '../../utils/consistant';

type Props = {
  modalActive: boolean;
  setModalActive(active: boolean): void;
  setModalContent(item?: Person): void;
  modalContent?: any;
};

function PeopleModal(props: Props) {
  const [closing, setClosing] = useState<boolean>(false);
  const [toggleNoscroll] = useScrollDisabled();
  const dispatch = useDispatch();

  const modalClose = () => {
    props.setModalActive(!props.modalActive);
    toggleNoscroll();
    dispatch(hideMenu());
  };

  useEffect(() => {
    if (props.modalActive) return;
    setClosing(true);
    setTimeout(() => {
      props.setModalContent();
      setClosing(false);
    }, consistant.pageTransitionDuration);
  }, [props.modalActive]);

  return (
    <section
      className={cn('people-modal', {
        'people-modal_open': props.modalActive,
        'people-modal_close': closing,
      })}
    >
      <div className="modal-inner">
        <div
          className="modal-left"
          style={{ backgroundImage: `url(${props.modalContent?.acf.image.sizes.large})` }}
        ></div>
        <div className="modal-right">
          <div className="modal-close" onClick={() => modalClose()}>
            <div className="menu-bar">
              <span className="bar bar-open"></span>
            </div>
          </div>
          <div className="modal-right_inner">
            <div className="introduction">
              <p className="name">{props.modalContent?.acf.name}</p>
              <p className="position">{props.modalContent?.acf.job}</p>
              <p className="text r">{props.modalContent?.acf.content}</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default PeopleModal;
