import React, { useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { updateScrollMenuColor } from '../../redux/index';

type Props = {
  updatePosition(top: number, bottom: number): void;
  children: any;
};

function ScrollMenuClass(props: Props) {
  const dispatch = useDispatch();
  const ref = useRef<HTMLDivElement>(null);

  const scrollFunc = (ref: any) => {
    return () => {
      if (!ref) return;
      if (ref.current) {
        let targrtTop: number = ref.current.getBoundingClientRect().top;
        let endOffSet: number = targrtTop + ref.current.clientHeight;
        props.updatePosition(targrtTop, endOffSet);
      }
    };
  };
  useEffect(() => {
    window.addEventListener('scroll', scrollFunc(ref));
    return () => {
      window.removeEventListener('scroll', scrollFunc(ref));
    };
  }, []);

  return <div ref={ref}>{props.children}</div>;
}

export default ScrollMenuClass;
