import React, { useEffect, useState } from 'react';
import Slider from 'react-slick';
import PeopleModal from '../common/PeopleModal';
import '../../styles/top/people.scss';
import { Link } from 'react-router-dom';
import { Person } from '../../types/person';
import { useScrollDisabled } from '../../hooks/useScrollDisabled';
import { useDispatch } from 'react-redux';
import { hideMenu } from '../../redux';

type Props = {
  people: [Person];
};

function People(props: Props) {
  const dispatch = useDispatch();
  const [toggleNoscroll] = useScrollDisabled();
  const [modalActive, setModalActive] = useState<boolean>(false);
  const [modalContent, setModalContent] = useState<Person | undefined>();

  const modalChage = (item?: Person) => {
    setModalActive(!modalActive);
    toggleNoscroll();
    setModalContent(item);
    dispatch(hideMenu());
  };

  const settings = {
    dots: false,
    arrows: true,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    centerMode: false,
    variableWidth: true,
    responsive: [
      {
        breakpoint: 767,
        settings: {
          arrows: false,
          slidesToShow: 2,
        },
      },
    ],
  };

  return (
    <section className="people" id="people">
      <div className="section-padding">
        <p className="base-title f-black">
          People
          <Link to="people">
            <p className="view-all">
              View All
              <span></span>
            </p>
          </Link>
        </p>
      </div>
      <Slider className="slider" {...settings}>
        {props.people &&
          props.people.map((item: Person, key: number) => {
            return (
              <div
                key={key}
                className="slide-item img-hover"
                onClick={() => {
                  if (item.acf.name === '') return;
                  modalChage(item);
                }}
              >
                <img src={item.acf.image.url} alt={item.acf.image.alt} />
                <p className="name">{item.acf.name}</p>
                <p className="job">{item.acf.job}</p>
              </div>
            );
          })}
        <div className="slide-last">
          <Link to="people">
            <p>View All</p>
          </Link>
        </div>
      </Slider>
      <PeopleModal
        modalContent={modalContent}
        modalActive={modalActive}
        setModalContent={setModalContent}
        setModalActive={setModalActive}
      />
    </section>
  );
}

export default People;
