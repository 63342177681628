import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import VisibilitySensor from 'react-visibility-sensor';
import { delay } from '../../utils/delay';
import '../../styles/top/solution.scss';




function Solution() {
  const [scrollFlag, setScrollFlag] = useState(false);
  const [circleStepOne, setCircleStepOne] = useState(false);
  const [circleStepTwo, setCircleStepTwo] = useState(false);
  const [circleStepThree, setCircleStepThree] = useState(false);
  function onChange (isVisible:boolean) {
    setScrollFlag(isVisible);
  }
  useEffect(()=>{
    if(!scrollFlag)return;
    (async()=>{
      setCircleStepOne(true)
      setCircleStepTwo(true)
      setCircleStepThree(true)
    })()
  },[scrollFlag])
  return(
    <section className="solution" id="solution">
      <p className="base-title f-sky_blue">Solution</p>
      <div className="solution-inner">
        <div className="left-contents r">
          <p>企業の社会的価値となるコアバリューを創出し、それを体現する象徴的な活動をデザインすることで、メディア、SNSを含むステークホルダーを巻き込んだ情報流通を実現します。</p>
          <Link to="/sdgs">
            <div className="sdgs_link i-b">
              <div>
                ESG/SDGs PR 
                <p className="db">について</p>
              </div>
              <span></span>
            </div>
          </Link>
        </div>
        <VisibilitySensor onChange={onChange}>
          <div className="right-contents">
            <div className={["arrow", "d-flex", "align-center", circleStepOne ? 'arrow_active' : ''].join(' ')}>
              <div className={["arrow-l", circleStepOne ? 'arrow-l_active' : ''].join(' ')}></div>
              <div className="arrow-r"></div>
            </div>
            <div className={["circle", "circle-l", circleStepThree ? 'circle-l_active' : ''].join(' ')}></div>
              <span className={["pc_only", "circle-l_text", circleStepThree ? 'circle-l_text_active' : ''].join(' ')}>
                Media Relations<br/>
                Social Media Communication<br/>
                Customer Experience
              </span>
            <p className={["sp_only", "circle-l_text", circleStepThree ? 'circle-l_text_active' : ''].join(' ')}>
              <p>
                <span>Media Relations</span>
              </p>
              <p>
              <span>
                Social Media<br/>
                Communication
              </span>
              </p>
              <p>
                <span>
                  Customer <br/>
                  Experience
                </span>
              </p>
            </p>
            <div className={["circle", "circle-m", circleStepTwo ? 'circle-m_active' : ''].join(' ')}></div>
            <span className={['circle-m_text', circleStepTwo ? 'circle-m_text_active' : ''].join(' ')}>Core <br className="sp_only"/>Activation</span>
            <div className={['circle', 'circle-s', circleStepOne ? 'circle-s_active' : ''].join(' ')}></div>
            <span className={['circle-s_text', circleStepOne ? 'circle-s_text_active' : ''].join(' ')}>Core <br className="sp_only"/>Value</span>
          </div>
        </VisibilitySensor>
      </div>
    </section>
  )
}

export default Solution;