import * as React from 'react';
import '../../styles/common/text_delay.scss';

type Props = {
  children?: any;
  className?: string;
  offset?: number;
};

const TextDelay = (props: Props) => {
  let offset = 100;
  const interval = 30;
  const words = props.children.split(' ');
  if (props.offset) offset = props.offset;
  return (
    <span className={props.className}>
      {words.map((word: string, index: number) => (
        <>
          {index !== 0 && <>&nbsp;</>}
          <span className="word" key={index}>
            {word.split('').map((char: any, idx: number) => {
              const i: number =
                idx +
                words
                  .map((word: string) => word.length)
                  .slice(0, index)
                  .reduce((prev: number, current: number) => prev + current, 0);
              return (
                <span className="char" style={{ transitionDelay: `${offset + i * interval}ms` }}>
                  {char}
                </span>
              );
            })}
          </span>
        </>
      ))}
    </span>
  );
};

export default TextDelay;
