import '../../styles/common/loading.scss';
import { useRef, useEffect, useState } from 'react';
import Wrapper from './Wrapper';
import cn from 'classnames';

type Props = {
  progress: number;
  startClose: boolean;
};

export default function App(props: Props) {
  const [contentHeight, setContentHeight] = useState<number>(0);

  useEffect(() => {
    return () => {};
  }, []);

  return (
    <div className={cn('loading', { close: props.startClose })}>
      <div className="inner">
        <Wrapper>
          <section>
            <span className="barBg">
              <span className="bar" style={{ width: `${props.progress}%` }} />
            </span>
          </section>
        </Wrapper>
      </div>
    </div>
  );
}
