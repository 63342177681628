import { PayloadAction } from '@reduxjs/toolkit';
import { State } from './state';
import Cookies from 'js-cookie';

export const reducers = {
  toggleMenu: (state: State): State => {
    return { ...state, menuOpen: !state.menuOpen };
  },
  hideMenu: (state: State): State => {
    return { ...state, hideMenu: !state.hideMenu };
  },
  updateMenuOpenStatus: (state: State, action: PayloadAction<boolean>): State => {
    return { ...state, menuOpen: action.payload };
  },
  toggleCursor: (state: State): State => {
    return { ...state, cursorActive: !state.cursorActive };
  },
  updateCursorType: (state: State, action: PayloadAction<string>): State => {
    return { ...state, cursorType: action.payload };
  },
  updateCursorStatus: (state: State, action: PayloadAction<boolean>): State => {
    return { ...state, cursorActive: action.payload };
  },
  updateScrollTarget: (state: State, action: PayloadAction<string>): State => {
    return { ...state, scrollTarget: action.payload };
  },
  updateScrollMenuColor: (state: State, action: PayloadAction<boolean>): State => {
    return { ...state, scrollMenuColor: action.payload };
  },
  updatePrNowDate: (state: State, action: PayloadAction<string>): State => {
    return { ...state, prNowUpdated: action.payload };
  },
};
