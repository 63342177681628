import { fetch, fetchNote } from '../utils/api';

export const doFetch = async (endpoint: string) => {
  try {
    const result = await fetch(endpoint);
    return result.data;
  } catch (error) {
    console.error(error);
  }
};

export const doFetchNote = async () => {
  try {
    const result = await fetchNote();
    return result.data;
  } catch (error) {
    console.error(error);
  }
};
