export type State = {
  menuOpen: boolean;
  hideMenu: boolean;
  cursorActive: boolean;
  cursorType: string;
  scrollTarget: string;
  scrollMenuColor: boolean;
  prNowUpdated: string | null;
};

export const initialState: State = {
  menuOpen: false,
  hideMenu: false,
  cursorActive: false,
  cursorType: '',
  scrollTarget: '',
  scrollMenuColor: false,
  prNowUpdated: null,
};
