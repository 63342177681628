import dayjs from 'dayjs';

export const convert = (contents: any) => {
  const dates = contents.sort((a: any, b: any) => a.id < b.id);
  return dayjs(dates[0].publishAt).format("'YY.M.DD");
};

export const convertBlog = (data: any) => {
  if (!data) {
    return ''
  }
  
  const dates = data.sort((a: any, b: any) => {
    const dateA = dayjs(a.acf.display_date);
    const dateB = dayjs(b.acf.display_date);
    return dateA.isBefore(dateB)
  });
  return dayjs(dates[0].acf.display_date).format("'YY.M.DD");
};
