import React, { useEffect, useState, useRef } from 'react';
import LazyLoad from 'react-lazyload';
import { useScrollDisabled } from '../hooks/useScrollDisabled';
import { doFetch } from '../hooks/useApi';

import { delay } from '../utils/delay';
import Loading from '../components/common/Loading';
import Wrapper from '../components/common/Wrapper';
import Header from '../components/common/Header';
import Footer from '../components/common/Footer';
import PeopleModal from '../components/common/PeopleModal';
import TextDelay from '../components/common/TextDelay';
import '../styles/common/reset.css';
import '../styles/common/layout.scss';
import cn from 'classnames';
import { useBottomScrollListener } from 'react-bottom-scroll-listener';
import { Img } from 'react-image';

import { Person } from '../types/person';
import ScrollToTop from '../components/common/ScrollToTop';
import { useDispatch } from 'react-redux';
import { hideMenu } from '../redux';
import consistant from '../utils/consistant';
import { Helmet } from 'react-helmet';

function People() {
  const dispatch = useDispatch();
  const PAGING = 20;
  //loading
  const [progress, setProgress] = useState<number>(0);
  const [loadingVisible, setLoadingVisible] = useState<boolean>(true);
  const [close, setClose] = useState<boolean>(false);
  const [mainTextDelayFlag, setMainTextDalayFlag] = useState<boolean>(false);

  const [scrollY, setScrollY] = useState<number>(0);
  const [toggleNoscroll] = useScrollDisabled();
  const [modalActive, setModalActive] = useState<boolean>(false);
  const [modalContent, setModalContent] = useState<Person | undefined>();

  const [data, setData] = useState<Person[]>([]);
  const [nextPage, setNextPage] = useState<number>(1);
  const [finished, setFinished] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const modalChage = (item?: Person) => {
    setModalActive(!modalActive);
    toggleNoscroll();
    setModalContent(item);
    dispatch(hideMenu());
  };

  const scroll = () => {
    setScrollY(window.scrollY);
  };

  const bottomScrollCallback = () => {
    if (finished) return;
    if (loading) return;
    setLoading(true);
    (async () => {
      const nextData = await doFetch(`/people?per_page=${PAGING}&page=${nextPage}`);
      if (!nextData) {
        setFinished(true);
        setLoading(false);
        return;
      }
      setData([...data, ...nextData]);
      setLoading(false);
      setNextPage(nextPage + 1);
    })();
  };

  useBottomScrollListener(bottomScrollCallback, { offset: 300 });

  useEffect(() => {
    window.addEventListener('scroll', scroll);
    window.gtag('config', window.GA_MEASUREMENT_ID, { page_path: '/people' });
    (async () => {
      const data = await doFetch(`/people?per_page=${PAGING}&page=${1}`);
      setData(data);
      setNextPage(2);
      setProgress(100);

      //closing loading
      await delay(800);
      setClose(true);
      await delay(consistant.pageTransitionDuration);
      setLoadingVisible(false);
      setMainTextDalayFlag(true);
    })();

    return () => {
      window.removeEventListener('scroll', scroll);
    };
  }, []);

  return (
    <div className="People">
      <Helmet>
        <meta name="robots" content="noindex,nofollow" />
      </Helmet>
      <ScrollToTop />

      {loadingVisible && <Loading progress={progress} startClose={close} />}

      <Wrapper bgColor="#cfdbe6" dotColor="#c0cbd8">
        <Header />
        <section className="people-main">
          <h1>
            <TextDelay className={mainTextDelayFlag ? 'text-delay' : 'text-delay_hidden'}>
              People
            </TextDelay>
          </h1>
          <div className="contents">
            {data &&
              data.map((item: Person, index: number) => (
                <div
                  className={cn('item', { modalAvailable: item.acf.name !== '' })}
                  key={index}
                  onClick={() => {
                    if (item.acf.name === '') return;
                    modalChage(item);
                  }}
                >
                  <LazyLoad offset={-100} placeholder={<div className="img-hover"></div>}>
                    <div className="img-hover">
                      <Img
                        className="fadein"
                        src={item.acf.image.sizes.large}
                        loader={<img className="fadeout" />}
                      />
                    </div>
                  </LazyLoad>
                  <p className="name">{item.acf.name}</p>
                  <p className="position">{item.acf.name !== '' && item.acf.job}</p>
                </div>
              ))}
          </div>
        </section>
      </Wrapper>
      <Footer />

      <PeopleModal
        modalContent={modalContent}
        modalActive={modalActive}
        setModalContent={setModalContent}
        setModalActive={setModalActive}
      />
    </div>
  );
}

export default People;
