import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { updateCursorType, updateCursorStatus } from '../../redux/index';
import { Work } from '../../types/work';
import { delay } from '../../utils/delay';
import '../../styles/top/works.scss';
import { Link } from 'react-router-dom';
import cn from 'classnames';
import VisibilitySensor from 'react-visibility-sensor';
import TextDelay from '../common/TextDelay';

type Props = {
  works: Work[]
}

function Works(props: Props) {
  const dispatch = useDispatch();
  const [count, setCount] = useState<number>(0);
  const [inactive, setInactive] = useState<boolean>(false);
  const [textHidden, setTextHidden] = useState<boolean>(false);
  const [lowerDown, setLowerDown] = useState<boolean>(false);
  const [fadeOut, setFadeOut] = useState<boolean>(false);
  const slideAmount = props.works.length;

  function onChange (isVisible:boolean) {
    if(!isVisible) dispatch(updateCursorStatus(false));
  }


  const onMouseOver = (mouseType: string) => {
    dispatch(updateCursorStatus(true));
    dispatch(updateCursorType(mouseType));
  }
  const onMouseOut = () => {
    dispatch(updateCursorStatus(false));
  }

  const updateCount = async (updateType: string) => {
    //連続クリック防止
    if(inactive) return
    setInactive(true);
    //テキストfade-out
    setFadeOut(true);

    await delay(100);
    //タイトル上隠れるモーション
    setTextHidden(true);

    await delay(200);
    //タイトルの位置を下にセット
    setLowerDown(true);
    
    await delay(700);
    //タイトル上隠れるモーション棄却
    setTextHidden(false);
    //画像スライド(タイトル食い気味にする為、cssでdelayしている)
    if (updateType === 'next') setCount(count + 1);
    if (updateType === 'prev') setCount(count + slideAmount - 1);
    await delay(750);
    //タイトルを下から出現
    setLowerDown(false);

    await delay(900);
    //テキストfade-in
    setFadeOut(false);
    //クリック許可
    setInactive(false);
  }
  return (
    <section className="works" id="works">
      <VisibilitySensor onChange={onChange}>
        <div className="works-inner">
          <div className="slide-left pc_only">
            {props.works ? props.works.map((item: Work, index: number) => {
              return (
                <div className={cn('slide-item',{
                    'is-active': index === (count + slideAmount - 1) % slideAmount,
                    'is-before': index === (count + slideAmount - 2) % slideAmount,
                  })}>
                  <div className="blend-navy">
                    <img src={item.acf.thumbnail.url} alt={item.acf.thumbnail.alt} />
                  </div>
                </div>
              )
            }) : null}
          </div>
          <div className="slide-center">
            {props.works ? props.works.map((item: Work, index: number) => {
              return (
                <Link to={'works/' + item.id} key={index}>
                  <div className={cn('slide-item',{
                      'is-active': index === count % slideAmount,
                      'is-before': index === (count - 1) % slideAmount,
                    })}>
                    <div className="img-wrap">
                      <img src={item.acf.thumbnail.url} alt={item.acf.thumbnail.alt} />
                    </div>
                  </div>
                </Link>
              )
            }) : null}
            {props.works ? props.works.map((item: Work, index: number) => {
              return (
                <div className='img-text_box'>
                  {index === count % slideAmount ?  
                    <div>
                    <span className={cn('img-id', 'r', 'fade-in', {
                      'fade-out': fadeOut
                    })}>
                      {index + 1}/{props.works.length}
                    </span>
                    <p className="title">
                      <TextDelay 
                        className={cn('title-text', {
                          'text-hidden': textHidden,
                          'text-show': lowerDown
                        })}
                      >
                        {item.acf.title}
                      </TextDelay> 
                    </p>
                    <div>
                      <span className={cn('sub-title', 'fade-in', {
                        'fade-out': fadeOut
                      })}>
                        {item.acf.client}
                      </span>
                      {item.acf.tags.map((tag, key) => {
                        return (
                          <span className={cn('text', 'fade-in', {
                            'fade-out': fadeOut
                          })} 
                          key={key}>
                            {tag.tag} <br />
                          </span>
                        )
                      })}
                    </div>
                  </div>: null}
                </div>
              )
            })
            : null}
          </div>
          <div className="slide-right">
            {props.works ? props.works.map((item: Work, index: number) => {
              return (
                <div className={cn('slide-item', {
                    'is-active': index === (count + 1) % slideAmount,
                    'is-before': index === count % slideAmount,
                  })}>
                  <div className="blend-navy">
                    <img src={item.acf.thumbnail.url} alt={item.acf.thumbnail.alt} />
                  </div>
                </div>
              )
            }) : null}
          </div>
        </div>
      </VisibilitySensor>
      <div
        className="prev-click pc_only"
        onMouseOver={ () => onMouseOver('PREV') }
        onMouseOut={ onMouseOut }
        onClick={ () => updateCount('prev') }
        >
      </div>
      <div 
        className="next-click pc_only"
        onMouseOver={ () => onMouseOver('NEXT') }
        onMouseOut={ onMouseOut }
        onClick={ () => updateCount('next') }
        >
      </div>
      <div 
        className="sp_next_click sp_only"
        onClick={ () => updateCount('next') }
      >
        NEXT
      </div>
    </section>
  );
}

export default Works;
