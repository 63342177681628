import React, { useEffect, useState } from 'react';
import { useScrollDisabled } from '../../hooks/useScrollDisabled';
import { updatePrNowDate } from '../../redux/index';
import { doFetch } from '../../hooks/useApi';
import { convertBlog } from '../../utils/dateFromContents';


import { useSelector, useDispatch } from 'react-redux';
import { State } from '../../redux/state';
import { toggleMenu } from '../../redux/index';
import '../../styles/common/header_contents.scss';
import cn from 'classnames';

const HeaderContents = React.forwardRef((props: any, ref: any) => {
  const prNowUpdated: string | null = useSelector((state: State) => state.prNowUpdated);
  const [toggle] = useScrollDisabled();
  const menuOpen: boolean = useSelector((state: State) => state.menuOpen);
  const scrollMenuColor: boolean = useSelector((state: State) => state.scrollMenuColor);
  const dispatch = useDispatch();

  const onClick = () => {
    // アイコンの表示変更
    dispatch(toggleMenu());
    // メニュー表示
    props.setMenuActive(!menuOpen);
    // スクロール禁止
    toggle();
  };

  useEffect(() => {
    (async () => {
      if (!prNowUpdated) {
        // const note: any = await doFetchNote();
        const blog: any = await doFetch('/blog');
        dispatch(updatePrNowDate(convertBlog(blog)));
      }
    })();
  }, []);

  return (
    <div className="header-contents" ref={ref}>
      <div className="menu-bar hover-opacity" onClick={onClick}>
        <span
          className={cn(
            'bar',
            { ['bar-open']: menuOpen },
            props.lightTheme ? 'bar_black' : 'bar_skyblue'
          )}
        ></span>
      </div>
      <div className="header-now">
        <a className="header-now_top" href="/blog">
          <span>BLOG</span>
        </a>
        <span
          className={cn('update_day', 'r', {
            update_day_black: menuOpen || props.lightTheme,
            update_day_white: !props.lightTheme,
          })}
        >
          {prNowUpdated} 更新
        </span>
      </div>
    </div>
  );
});
export default HeaderContents;
