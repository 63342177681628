import React, { useEffect, useState } from 'react';
import Wrapper from '../components/common/Wrapper';
import Loading from '../components/common/Loading';

import Header from '../components/common/Header';
import Footer from '../components/common/Footer';
import ScrollToTop from '../components/common/ScrollToTop';
import TextDelay from '../components/common/TextDelay';
import { delay } from '../utils/delay';
import '../styles/common/layout.scss';
import consistant from '../utils/consistant';

function Sdgs() {
  const [mainTextDelayFlag, setMainTextDalayFlag] = useState<boolean>(false);
  const [progress, setProgress] = useState<number>(0);
  const [loadingVisible, setLoadingVisible] = useState<boolean>(true);
  const [close, setClose] = useState<boolean>(false);

  useEffect(() => {
    (async () => {
      window.gtag('config', window.GA_MEASUREMENT_ID, {'page_path': '/sdgs'});
      await delay(800);
      setProgress(100);

      //closing loading
      await delay(800);
      setClose(true);
      await delay(consistant.pageTransitionDuration);
      setLoadingVisible(false);
      setMainTextDalayFlag(true);
    })();
  }, []);

  return (
    <div className="Sdgs">
      <ScrollToTop />
      {loadingVisible && <Loading progress={progress} startClose={close} />}
      <Wrapper>
        <Header />
        <section className="first-view">
          <h1 className="db">
            <strong>
              <TextDelay className={['strong', mainTextDelayFlag ? "text-delay" : "text-delay_hidden"].join(' ')}>
                ESG
              </TextDelay>
              <span>
                <TextDelay className={['strong','span',mainTextDelayFlag ? "text-delay" : "text-delay_hidden"].join(' ')} offset={190}>
                  /
                </TextDelay>
              </span>
              <TextDelay className={['strong',mainTextDelayFlag ? "text-delay" : "text-delay_hidden"].join(' ')} offset={260}>
                SDGs PR
              </TextDelay>
            </strong>
            <br className="sp_only"/>
            <TextDelay className={[mainTextDelayFlag ? "text-delay" : "text-delay_hidden"].join(' ')} offset={430}>
              を軸にしたコミュニケーション
            </TextDelay>
          </h1>
          <p className="heading db">活動フレームワーク</p>
          <p className="text r">
            企業が社会との持続的な関係構築をおこなっていくために、プロジェクトのPDCAサイクルを設計。
            <br />
            長期的な視野で企業価値向上に貢献します。
          </p>
        </section>
        <div className="border"></div>
        <section className="sdgs-contents">
          <div className="contents-block">
            <p className="num">01.</p>
            <div className="content-inner">
              <h2 className="db">
                <span>ESG/SDGs PR</span> への理解、
                <br className="pc_only" />
                自社の現状分析と課題抽出
              </h2>
              <p className="description r">
                クライアント企業様とパートナーシップを構築し、ESG/SDGsの観点から、独自のKPIスコアを活用し、
                現状理解と理想のゴール設定、社会環境の調査、ステークホルダーのインサイト分析、メディア論調分析等をおこない、
                将来を見据えた企業の社会的価値を創造するためのコアバリューを立案。
              </p>
              <ul className="action-list r">
                <li>
                  ESG/SDGsの重要課題<span>（マテリアリティ）</span>に関するコンサルティング
                </li>
                <li>ダイバーシティコンサルティング</li>
                <li>ESGスコアリング</li>
                <li>ステークホルダーインサイト分析</li>
                <li>メディア論調、ソーシャルインサイト分析</li>
                <li>コミュニケーション・ストーリーの構築</li>
              </ul>
            </div>
          </div>

          <div className="border"></div>

          <div className="contents-block">
            <p className="num">02.</p>
            <div className="content-inner">
              <h2 className="db">
                経営戦略に紐づいた
                <br />
                コミュニケーション
                <br className="sp_only" />
                戦略設計への落とし込み
              </h2>
              <p className="description r">
                課題設定に基づき、企業価値を最大化するための象徴的なアクションを開発。
                同活動がすべてのステークホルダーに伝わるためのクリエイティブから最適な情報波及までデザインします。
              </p>
              <ul className="action-list r">
                <li>
                  SX<span>（サステナビリティ・トランスフォーメーション）</span>の構築
                </li>
                <li>コンテンツクリエイティブ</li>
                <li>ステークホルダーインサイト分析</li>
                <li>外部団体との共創プロジェクト</li>
                <li>社会価値（ESG/SDGs）を体現する新たな取り組みの構築</li>
              </ul>
            </div>
          </div>

          <div className="border"></div>

          <div className="contents-block">
            <p className="num">03.</p>
            <div className="content-inner">
              <h2 className="db">
                社内・社外向け
                <br className="sp_only" />
                コミュニケーションの遂行
              </h2>
              <p className="description r">
                戦略を正しく、より強力に実行・具現化するために、コミュニケーション全般のディレクションから実行まで、
                ベクトルグループが有するソリューションを駆使しワンストップでスピーディに実現します。
              </p>
              <ul className="action-list r">
                <li>マーケティングコミュニケーション</li>
                <li>インターナルコミュニケーション</li>
                <li>社内外にむけたオウンドメディア制作</li>
                <li>メディアリレーション</li>
                <li>ソーシャルメディアコミュニケーション</li>
                <li>IR</li>
                <li>統合報告書の作成</li>
              </ul>
            </div>
          </div>

          <div className="border"></div>

          <div className="contents-block">
            <p className="num">04.</p>
            <div className="content-inner">
              <h2 className="db">プロジェクトレビュー / 改善・強化施策の立案</h2>
              <p className="description r">
                プロジェクトによって広めたメッセージの質とステークホルダーからのリアクションに価値をおいた結果分析。
                その後の事業成長に向けたアクションの再定義、改善・強化施策を立案し、投資対効果を最大化します。
              </p>
              <ul className="action-list r">
                <li>ESGスコアリング</li>
                <li>ステークホルダー調査</li>
                <li>ブランドリフト調査</li>
                <li>メディア露出数・論調分析</li>
              </ul>
            </div>
          </div>
        </section>
      </Wrapper>
      <Footer />
    </div>
  );
}

export default Sdgs;
