import React, { useEffect, useState } from 'react';
import { useLocation, withRouter } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { useScrollDisabled } from '../../hooks/useScrollDisabled';
import { toggleMenu, updateScrollTarget } from '../../redux/index';
import { State } from '../../redux/state';
import useInterval from 'use-interval';
import cn from 'classnames';
import '../../styles/common/menu.scss';
import consistant from '../../utils/consistant';

function Menu(props: any) {
  const [closing, setClosing] = useState<boolean>(false);
  const menuOpen: boolean = useSelector((state: State) => state.menuOpen);
  const [toggle] = useScrollDisabled();
  const dispatch = useDispatch();
  const location = useLocation();
  const [count, setCount] = useState<number>(1);
  const slideAmount = 3;

  const menuClose = (id: string) => {
    if (props.blog) {
      document.location.href = `/#${id}`;
    } else {
      toggle();
      dispatch(toggleMenu());
      props.setMenuActive(false);
      dispatch(updateScrollTarget(id));

      if (location.pathname !== '/') {
        props.history.push(`/#${id}`);
      }
    }
  };

  useEffect(() => {
    countUp();
  }, []);

  useEffect(() => {
    if (props.menuActive) return;
    setClosing(true);
    setTimeout(() => {
      setClosing(false);
    }, consistant.pageTransitionDuration);
  }, [props.menuActive]);

  const countUp = () => {
    setCount(count + 1);
  };

  useInterval(countUp, 7000);

  return (
    <section className={cn('menu', { 'menu-open': props.menuActive, 'menu-close': closing })}>
      <div className="menu-inner">
        <div className="menu-img">
          {Array(slideAmount)
            .fill(null)
            .map((item, index) => {
              return (
                <figure
                  key={index}
                  className={cn({
                    'is-active': index === count % slideAmount,
                    'is-before': index === (count - 1) % slideAmount,
                  })}
                >
                  <img src={`${IMAGE_PATH}images/img-menu-0${index + 1}.jpg`} alt="" />
                </figure>
              );
            })}
        </div>
        <div className="menu-contents">
          <div className="contents-inner">
            <ul className="contents">
              <li onClick={() => menuClose('works')}>Works</li>
              <li onClick={() => menuClose('solution')}>Solution</li>
              <li onClick={() => menuClose('news')}>News</li>
              <li onClick={() => menuClose('people')}>People</li>
              <li onClick={() => menuClose('vision')}>Vision</li>
              <li onClick={() => menuClose('ceo-message')}>CEO Message</li>
            </ul>
            <div className="info r">
              <p>
                〒107-0052 東京都港区赤坂
                <br className="sp_only" />
                ４丁目１５−1 赤坂ガーデンシティ14F
                <br />
                <span className="pc_only">Tel: 03-5572-6070</span>
                <a href="tel:03-5572-6070" className="tel sp_only">
                  Tel: <span>03-5572-6070</span>
                </a>
              </p>
              <div>
                <ul className="links">
                  <li>
                    <a href="mailto:platinum_pr@vectorinc.co.jp" rel="noreferrer" target="_blank">
                      Contact<span>　/　</span>
                    </a>
                  </li>
                  <li>
                    <a href="https://prnow.platinuminc.jp/" rel="noreferrer" target="_blank">
                      note<span>　/　</span>
                    </a>
                  </li>
                  <li>
                    <a href="https://twitter.com/Platinum_vector" rel="noreferrer" target="_blank">
                      Twitter<span>　/　</span>
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.facebook.com/platinuminc.pr"
                      rel="noreferrer"
                      target="_blank"
                    >
                      Facebook<span>　/　</span>
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://note.com/platinum_career/"
                      rel="noreferrer"
                      target="_blank"
                    >
                      Careers
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default withRouter(Menu);
