import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import consistant from '../../utils/consistant';

export default function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, consistant.pageTransitionDuration);
  }, [pathname]);

  return null;
}
