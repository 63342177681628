import React, {useEffect, useState} from 'react';
import Slider from 'react-slick';
import { Service } from '../../types/service';
import '../../styles/top/services.scss';

type Props = {
  services: [Service];
}

function Services(props: Props) {
  const settings = {
    dots: false,
    arrows: true,
    infinite: false,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    centerMode: true,
    variableWidth: true,
    responsive: [
      {
        breakpoint: 666,
        settings: {
          arrows: false,
          slidesToShow: 1,
          slidesToScroll: 1,
          centerMode: false,
        }
      }
    ]
  };

  return (
    <section className="services">
      <div className="section-padding">
        <p className="base-title f-sky_blue">Services</p>
      </div>
      <Slider className="slider" {...settings}>
        {props.services ? props.services.map((item: Service, key: number) => {
            return (
              <div className="slide-item" key={key}>
                <p 
                  className="item-title db"
                  dangerouslySetInnerHTML= {{__html: item.acf.title}}
                ></p>
                <p className="item-text r">{item.acf.content}</p>
              </div>
            )
          })
        
        : null }
      </Slider>

    </section>
  )
}

export default Services;