export const useScrollDisabled = () => {
  const htmlTag = document.querySelector('html');
  const toggle = () => {
    htmlTag?.classList.toggle('no-scroll');
  };

  const scrollDisable = () => {
    htmlTag?.classList.add('no-scroll');
  };
  const scrollEnable = () => {
    htmlTag?.classList.remove('no-scroll');
  };

  return [toggle, scrollDisable, scrollEnable];
};