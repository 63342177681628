import React, { useEffect, useState, useRef } from 'react';
import { State } from '../redux/state';
import { useSelector, useDispatch } from 'react-redux';
import { updateScrollTarget } from '../redux/index';
import '../styles/common/reset.css';
import { doFetch } from '../hooks/useApi';
import { delay } from '../utils/delay';
import VisibilitySensor from 'react-visibility-sensor';
import Header from '../components/common/Header';
import Works from '../components/index/Works';
import Solution from '../components/index/Solution';
import Services from '../components/index/Services';
import News from '../components/index/News';
import People from '../components/index/People';
import Footer from '../components/common/Footer';
import Wrapper from '../components/common/Wrapper';
import ScrollToTop from '../components/common/ScrollToTop';
import ScrollMenuClass from '../components/common/ScrollMenuClass';
import Loading from '../components/common/Loading';
import NewsArrivalNews from '../components/index/NewArrivalNews';
import Cursor from '../components/common/Cursor';
import TextDelay from '../components/common/TextDelay';
import '../styles/common/layout.scss';
import { Work } from '../types/work';
import { Person } from '../types/person';
import { Information } from '../types/information';
import { Service } from '../types/service';
import consistant from '../utils/consistant';
import useBreakpoint from '../hooks/useBreakpoint';

function Top() {
  const [lightThemeTop, setLightThemeTop] = useState<number>(0);
  const [lightThemeBottom, setLightThemeBottom] = useState<number>(0);
  const [footerTopHeight, setFooterTopHeight] = useState<number>(0);
  const [works, setWorks] = useState<[Work]>();
  const [people, setPeople] = useState<[Person]>();
  const [informations, setInformations] = useState<[Information]>();
  const [services, setServices] = useState<[Service]>();
  const scrollTarget: string = useSelector((state: State) => state.scrollTarget);
  const [scrollFlag, setScrollFlag] = useState<boolean>(false);
  const [mainTextDelayFlag, setMainTextDalayFlag] = useState<boolean>(false);
  const [visitionScrollFlag, setVisitionScrollFlag] = useState<boolean>(false);
  const [missionScrollFlag, setMissionScrollFlag] = useState<boolean>(false);
  const [careersScrollFlag, setCareersScrollFlag] = useState<boolean>(false);
  const { breakpoint } = useBreakpoint();

  //loading
  const [progress, setProgress] = useState<number>(0);
  const [loadingVisible, setLoadingVisible] = useState<boolean>(true);
  const [close, setClose] = useState<boolean>(false);
  const dispatch = useDispatch();
  const ref = useRef<HTMLDivElement>(null);

  const scrollToContents = (target: string) => {
    const el: any = document.getElementById(target);
    if (el !== null) {
      el.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
      dispatch(updateScrollTarget(''));
    }
  };

  function visionOnChange(isVisible: boolean) {
    if (!isVisible) return;
    setVisitionScrollFlag(isVisible);
  }

  function missionOnChange(isVisible: boolean) {
    if (!isVisible) return;
    setMissionScrollFlag(isVisible);
  }
  function careersOnChange(isVisible: boolean) {
    if (!isVisible) return;
    setCareersScrollFlag(isVisible);
  }

  useEffect(() => {
    window.gtag('config', window.GA_MEASUREMENT_ID, { page_path: '/' });
    (async () => {
      //loading
      const works: [Work] = await doFetch('/works');
      setWorks(works);
      setProgress(100 / 4);

      const people: [Person] = await doFetch('/people');
      setPeople(people);
      setProgress((100 / 4) * 2);

      const informations: [Information] = await doFetch('/information');
      setInformations(informations);
      setProgress((100 / 4) * 3);

      const services: [Service] = await doFetch('/services');
      setServices(services);
      setProgress(100);

      //closing loading
      await delay(800);
      setClose(true);
      await delay(consistant.pageTransitionDuration);
      setLoadingVisible(false);
      setScrollFlag(true);
      setMainTextDalayFlag(true);

      initURLHash();
    })();
  }, []);

  if (scrollFlag) scrollToContents(scrollTarget);

  const initURLHash = () => {
    const hash = document.location.hash;
    if (hash.length > 0) {
      const target = hash.replace('#', '');
      setScrollFlag(true);
      scrollToContents(target);
    }
  };

  const ovservePosition = (top: number, bottom: number) => {
    setLightThemeBottom(bottom);
    setLightThemeTop(top);
    if (!ref.current) return;
    setFooterTopHeight(ref.current.getBoundingClientRect().top);
  };

  return (
    <div className="Top">
      {loadingVisible && <Loading progress={progress} startClose={close} />}
      <ScrollToTop />
      <Cursor />
      <Header lightThemeTop={lightThemeTop} lightThemeBottom={lightThemeBottom} />
      {informations ? (
        <NewsArrivalNews
          informations={informations}
          lightThemeTop={lightThemeTop}
          lightThemeBottom={lightThemeBottom}
          footerTopHeight={footerTopHeight}
        />
      ) : null}
      <Wrapper>
        {/* ヘッダー部分 */}
        <section className="main-text">
          <div className="text-wrap">
            <h1>
              <TextDelay className={mainTextDelayFlag ? 'text-delay' : 'text-delay_hidden'}>
                ONE MORE VALUE
              </TextDelay>
            </h1>
            <p className="db">
              {breakpoint === 'sp' ? (
                <>
                  <TextDelay
                    className={mainTextDelayFlag ? 'text-delay' : 'text-delay_hidden'}
                    offset={850}
                  >
                    私たちは価値を向上させ、
                  </TextDelay>
                  <br />
                  <TextDelay
                    className={mainTextDelayFlag ? 'text-delay' : 'text-delay_hidden'}
                    offset={1050}
                  >
                    企業と生活者と社会をつなぐ
                  </TextDelay>
                </>
              ) : (
                <TextDelay
                  className={mainTextDelayFlag ? 'text-delay' : 'text-delay_hidden'}
                  offset={850}
                >
                  私たちは価値を向上させ、企業と生活者と社会をつなぐ
                </TextDelay>
              )}
              <br />
              <TextDelay
                className={mainTextDelayFlag ? 'text-delay' : 'text-delay_hidden'}
                offset={1240}
              >
                コミュニケーションデザイン・カンパニーです
              </TextDelay>
            </p>
          </div>
        </section>
        {/* スライドバナー */}
        {works ? <Works works={works} /> : null}
        <section className="business-summary">
          <h2 className="db">
            価値を発掘する。
            <br />
            創造する。
            <br />
            伝播させる。
            <br />
            そして、世の中に、
            <br />
            前向きな変化を起こす。
          </h2>
          <div className="text-box r">
            <p>
              個人の趣味嗜好や価値観が多様化し、
              <br />
              情報が氾濫する時代の中で、
              <br />
              常に最新のコミュニケーションとは？を問い続け体現する。
            </p>
            <p className="second">
              生活者の興味や欲求、社会の潮流を起点に、
              <br />
              企業自身が気づかなった「価値」を発掘、創造する。
              <br />
              その価値を、細分化された世の中で、一人一人に届ける。
              <br />
              それが、プラチナムという集団です。
            </p>
            <p className="second">
              コミュニケーションの正解は目には見えない。
              <br />
              だからこそ、変革を楽しみ、行動と結果で示す。
            </p>
            <p className="second">私たちは共に、前向きな変化を世の中に起こします。</p>
          </div>
        </section>
        <div className="horizontal-bar section-width"></div>
        <Solution />
        <div className="horizontal-bar section-width"></div>
        {/* Servicesコンポーネント */}
        {services ? <Services services={services} /> : null}
        <div className="horizontal-bar section-width"></div>
        <section className="numbers">
          <p className="base-title">Platinum in Numbers</p>
          <ul className="contents-list">
            <li>
              <h3>500</h3>
              <p>Clients</p>
            </li>
            <li>
              <h3>3,312</h3>
              <p>Annual Projects</p>
            </li>
            <li>
              <h3>250</h3>
              <p>PR Persons</p>
            </li>
          </ul>
        </section>
      </Wrapper>
      {/* Newsコンポーネント */}
      <ScrollMenuClass updatePosition={ovservePosition}>
        {informations ? <News informations={informations} /> : null}
        <Wrapper bgColor="#cfdbe6" dotColor="#c0cbd8">
          {/* Peopleコンポーネント */}
          {people ? <People people={people} /> : null}
          <div className="horizontal-bar_skyblue section-width"></div>
          {/* Mission */}
          <section className="overview mission">
            <div className="d-flex">
              <p className="base-title f-black">Mission</p>
              <VisibilitySensor onChange={missionOnChange} offset={{ bottom: 100 }}>
                <h2 className="text">
                  <TextDelay className={missionScrollFlag ? 'text-delay' : 'text-delay_hidden'}>
                    One More Value.
                  </TextDelay>
                  <span className="db text_sub">
                    <TextDelay
                      className={missionScrollFlag ? 'text-delay' : 'text-delay_hidden'}
                      offset={550}
                    >
                      企業・商品の社会的価値を引き出し、
                    </TextDelay>
                    <br className="sp_only" />
                    <TextDelay
                      className={missionScrollFlag ? 'text-delay' : 'text-delay_hidden'}
                      offset={1060}
                    >
                      世の中に届ける。
                    </TextDelay>
                  </span>
                </h2>
              </VisibilitySensor>
            </div>
          </section>

          <div className="horizontal-bar_skyblue section-width"></div>
          <section className="overview dna">
            <div className="d-flex">
              <p className="base-title f-black">DNA</p>
              <div className="dna-contents">
                <div className="contents-inner">
                  <img src={`${IMAGE_PATH}images/dna-1.svg`} alt="" />
                  <h3>Professional</h3>
                  <p>
                    企業と社会、双⽅の利益を追求する
                    <br />
                    プロとして誇りを持つ
                  </p>
                </div>
                <div className="contents-inner">
                  <img src={`${IMAGE_PATH}images/dna-2.svg`} alt="" />
                  <h3>Proactive</h3>
                  <p>
                    与えられたことをやるのではなく
                    <br />
                    ⾃ら働きかけ、価値をつくる
                  </p>
                </div>
                <div className="contents-inner">
                  <img src={`${IMAGE_PATH}images/dna-3.svg`} alt="" />
                  <h3>Wildness</h3>
                  <p>
                    多様性を重んじ、型にはまらない
                    <br />
                    ⾃⾝の個性を武器に変える
                  </p>
                </div>
                <div className="contents-inner">
                  <img src={`${IMAGE_PATH}images/dna-4.svg`} alt="" />
                  <h3>Change+ble</h3>
                  <p>
                    変わり続ける社会に対応するため
                    <br />
                    ⾃⾝の変化も⽌めない
                  </p>
                </div>
              </div>
            </div>
          </section>
          <div className="horizontal-bar_skyblue section-width"></div>
          <section className="overview ceo-message section-padding" id="ceo-message">
            <p className="base-title f-black">CEO Message</p>
            <div className="message-box">
              <h3 className="db">
                “PRドリブン”で
                <br className="sp_only" />
                世の中の新しい価値を創造する
              </h3>
              <p className="r">
                時代に必要とされる、時代の先をいく、ちょっと新しい価値を創る。
                <br />
                その価値を、生活者に、社会に届けるのが我々プラチナムです。
                <br />
                <br />
                メディアだけでなく、生活者が様々なプラットフォームで情報発信が行うことができる情報過多時代において、ブランドや商品が選ばれし存在になるには、単なる発信活動では効果が見込めません。
                <br />
                <br />
                我々は、PRカンパニーとして培ってきた経験を活かし、クライアントのPRコミュニケーションをサポートするだけではなく、ブランドが保有する価値を発掘し、更にそのひとつ上の価値に昇華させるような価値規定を行います。
                <br />
                <br />
                その価値を元に、PR視点でのクリエイティブをいかしたコアアイデアで実装し、メディア、SNS、エクスペリエンス、デジタルマーケティングを駆使した最適な手法で届けていく。我々はそれを“PRドリブンIMC”と銘打ち、クライアントのブランドが、生活者に選ばれ、社会的価値のあるブランドになるようマーケティングコミュニケーションをして参ります。
                <br />
                <br />
                我々が大切にしていることのひとつは、クライアントを自分の会社だと思い、自社商品だと思い、パートナーとして並走することです。
                <br />
                <br />
                プラチナムは、PRドリブンIMCを通じてクライアントに貢献し、生活者に、社会に、前向きな変化が起こせるよう、これからもサービス提供を続けて参ります。
                <br />
                <br />
                <small>※IMC インテグレートマーケティングコミュニケーション</small>
              </p>
              <p className="name r">
                株式会社プラチナム
                <br />
                代表取締役 吉柳さおり
              </p>
            </div>
          </section>
        </Wrapper>
      </ScrollMenuClass>
      <section className="careers">
        <div
          className="careers-inner"
          style={{ backgroundImage: `url(${IMAGE_PATH}images/bg-careers.png)` }}
        >
          <div className="inner-texts">
            <VisibilitySensor onChange={careersOnChange}>
              <h3>
                <TextDelay className={careersScrollFlag ? 'text-delay' : 'text-delay_hidden'}>
                  Careers
                </TextDelay>
              </h3>
            </VisibilitySensor>
            <p className="db">
              PRのチカラでより良い社会創りに
              <br />
              貢献し、我々自身の成長を加速する
              <br />
              最高のメンバーを常に募集しています
            </p>
            <a href="https://note.com/platinum_career/" rel="noreferrer" target="_blank">
              <div className="wantedly-btn">
                <p className="wantedly-btn-text">Careers</p>
                {/* <img src={`${IMAGE_PATH}images/logo-wantedly.png`} alt="" /> */}
                <span></span>
              </div>
            </a>
          </div>
        </div>
      </section>
      <div ref={ref}>
        <Footer />
      </div>
    </div>
  );
}

export default Top;
