import React, { Children, useEffect, useRef, useState } from 'react';
import { State } from '../../redux/state';
import { useSelector } from 'react-redux';
import { gsap } from "gsap";
import '../../styles/common/cursor.scss';

function Cursor(props: any) {
  const cursorActive: boolean = useSelector((state: State) => state.cursorActive);
  const cursorType: string = useSelector((state: State) => state.cursorType);
  const ref:any = useRef();
  const [mouseX, setMouseX] = useState(0);
  const [mouseY, setMouseY] = useState(0);
  

  useEffect(() => {
    const mouseMoveListener = (event:any) => {
      if(ref.current) {
        gsap.to(ref.current, {
          duration: 1.5,
          ease: "power4.out"
        });
        setMouseX(event.clientX - (ref.current.clientHeight / 2));
        setMouseY(event.clientY - (ref.current.clientWidth / 2));
      }
    };
    window.addEventListener("mousemove", mouseMoveListener);
  }, []);

  return (
    <div 
      className={["cursor", cursorActive ? 'cursor-active' : ''].join(' ')} 
      style={{ transform: `translate(${mouseX}px, ${mouseY}px)` }}
      ref={ref}
    >
      <span>{ cursorType }</span>
    </div>
  )
}
export default Cursor;